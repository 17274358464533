<template>
  <tbody @click="$emit('click', index)" class="edit-goods-tbody" :class="{selected: selected, invalid: invalid}">
    <tr>
      <td v-for="column in ipRequestTable" :key="column.label"  :style="{'min-width': column.width + 'px' || 'auto', maxWidth: column.width + 'px' || 'auto', textAlign: column.align}">
        <div v-if="column.key === 'delete'">
          <img v-if="canDelete" class="close_times hidden" src="@/assets/images/icons/times.svg" @click.stop.prevent="$emit('delete-ipreq', index)">
        </div>
        <TwProcessGroupListEditIpRequestTableItem
          v-else
          :item="column.item"
          :selectIpreqIndex="selectIndex"
          :tableName="column.table"
          :arrayName="column.array"
          :groupName="column.group"
          :keyName="column.key"
          :readonly="column.readonly"
          :index="index"
          v-model="localValue"
          @onfocus="cellFocus"
          :isFrom="isFrom"
          :isInsurance="isInsurance"
        />
      </td>
    </tr>
  </tbody>
</template>

<script>
import _ from 'lodash';
// import BigNumber from 'bignumber.js';
// import { formatInputNumber } from '@/utils/searchUtil.js';
import TwProcessGroupListEditIpRequestTableItem from '@/components/molecules/TwProcessGroupListEditIpRequestTableItem';

export default {
  name: 'TwProcessGroupListEditIpRequestTable',
  props: {
    value: Object,
    selectIndex: Number,
    index: Number,
    selected: Boolean,
    ipRequestTable: Array,
    hasError: Array,
    isFrom: Boolean,
    isInsurance: Boolean
  },
  inject: ['s'],
  components: {
    TwProcessGroupListEditIpRequestTableItem
  },
  data() {
    return {
      pageSize: 20,
      sections: [],
      init: false,
      items: [],
      override: false
    };
  },
  computed: {
    canDelete() {
      return this.isFrom && !_.get(this, `localValue.processSeparate.ipreqList[${this.index}].ipreqInformationGrp.requestDatetime`);
    },
    localValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },
    invalid() {
      if (_.isEmpty(this.hasError)) {
        return false;
      }
      return _.some(this.hasError, e => {
        return e.startsWith(`processSeparate.ipreqList[${this.index}]`);
      });
    }
  },
  watch: {},
  methods: {
    cellFocus() {
      if (this.index !== this.selectIndex) {
        this.$emit('selectRow', this.index);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
img.close_times {
  opacity: 0;
  cursor: pointer;
}
tbody {
  background-color: white;
}
tbody:hover {
  background-color: $color_gray_background;

  img.close_times {
    opacity: 1;
  }
}

tbody.selected {
  background-color: $color_selected_row;
}

tbody.invalid td:not(.goods_line_table_inner) {
  background-color: rgba(224, 0, 1, 0.07);
}

tr {
  height: 40px;
}

th {
  text-align: center;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  word-break: break-word;
  color: #5e5873;
  padding: 4px 5px;
  border: 1px solid $color_gray_300;
  background: $color_dark_blue;
  color: white;
  // font-weight: bold;
}

td {
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  white-space: nowrap;
  padding: 0;
  border: 1px solid $color_gray_300;
  border-bottom: 0;

  .el-form-item {
    width: 100%;
    padding-right: 0;
    margin: 0;
    margin-bottom: 0 !important;

    ::v-deep {
      label.el-form-item__label {
        display: none;
      }

      .el-input,
      .el-select,
      .el-input-number,
      .el-input-number.amount {
        width: 100% !important;
      }

      .el-input-number .el-input__inner {
        text-align: right;
      }

      .el-input__inner {
        font-size: 12px;
        width: 100%;
        background: transparent;
        box-shadow: none;
        border-radius: 0;
        padding: 0 5px;

        &:not(:focus) {
          border: 0;
        }
      }

      .el-input.is-disabled .el-input__inner {
        color: $color_black;
        background-color: rgba($color_gray_300, 0.75);
      }

      .el-select {
        .el-input__inner {
          padding-right: 20px;
          overflow: hidden;
          white-space: nowrap;
        }
        .el-input__suffix {
          right: 0;
        }
      }

      .el-form-item__error {
        white-space: normal;
        font-size: 10px;
        left: 0;
        padding: 0 5px;
        margin-bottom: 5px;
      }

      .warning_message {
        width: 120px;
        font-size: 10px;
        background-position: 5px 2px;
        padding-left: 20px;
        margin-bottom: 5px;
        white-space: normal;
      }
    }
  }
}

td.goods_line_table_inner {
  position: relative;
  border-bottom: 1px solid $color_gray_400 !important;
  // border-top: 1px solid #666;

  img.close_times {
    position: absolute;
    right: 4px;
    top: 50%;
    margin-top: -10px;
    width: 20px;
    height: 20px;
    cursor: pointer;
    opacity: 0;
  }

  table {
    table-layout: fixed;
    min-width: 100%;
    border: 0;
    border-collapse: collapse;

    th {
      border-top: 0 !important;
      border-bottom: 0;
      padding: 0 5px;
      line-height: 0;
      height: 0;
      overflow: hidden;
      background: transparent;
    }

    td {
      border-top: 0 !important;
      border-bottom: 0 !important;
      padding: 0;
    }

    tr th:first-child,
    tr td:first-child {
      border-left: 0 !important;
    }

    tr th:last-child,
    tr td:last-child {
      border-right: 0 !important;
    }

    tr td:last-child {
      padding-right: 28px;
    }
  }
}
</style>
