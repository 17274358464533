<template>
  <el-form-item
    :label="item.label"
    :prop="`${tableName}.${arrayName}[${index}].${groupName}.${keyName}`"
    :class="{ full: item.inputType === 'text_full' || item.inputType === 'textarea', 'disabled-check': isReadonly && item.inputType === 'checkbox' }"
    :rules="rules"
  >
    <template v-if="((selectIpreqIndex !== null) && (index === selectIpreqIndex || index === selectIpreqIndex + 1 || index === selectIpreqIndex - 1)) || keyName === 'ipSubmitFlg'">
      <!-- Submit -->
      <div v-if="keyName === 'ipSubmitFlg' || item.inputType === 'checkbox'" class="goods_line_checkbox" :class="{ submit: keyName === 'ipSubmitFlg' }" style="text-align: center">
        <el-checkbox
          v-model="inputModel"
          :true-label="1"
          :false-label="0"
          :disabled="isReadonly"
          @change="onIpSubmitFlgCheck"
          @focus="onfocus"
        />
      </div>
      <!-- requestDatetime 表示のみ編集不可 -->
      <el-date-picker
        v-else-if="keyName === 'requestDatetime'"
        style="width:320px"
        v-model="requestDatetime"
        type="datetime"
        format="yyyy-MM-dd HH:mm"
        value-format="yyyy-MM-dd HH:mm"
        placeholder=""
        :disabled="true"
      />
      <!-- Date -->
      <el-date-picker
        v-else-if="item.inputType === 'date'"
        v-model="inputModel"
        type="date"
        format="yyyy-MM-dd"
        value-format="yyyy-MM-dd"
        placeholder=""
        :disabled="isReadonly"
        @change.native="onDateChange"
        @focus="onfocus"
      />
      <!-- アクション -->
      <el-select
        v-else-if="keyName === 'actionType'"
        v-model="inputModel"
        @change="onActionSelect"
        clearable
        :disabled="isReadonly"
        @focus="onfocus"
      >
        <el-option
          v-for="type in actionTypeVariables"
          :key="type.code"
          :label="type.label"
          :value="type.code"
        />
      </el-select>
      <!-- Request Type -->
      <el-select
        v-else-if="item.code === 'IP_REQUEST_TYPE'"
        v-model="inputModel"
        @change="onSelect"
        clearable
        :disabled="true"
        @focus="onfocus"
      >
        <el-option
          v-for="type in IP_REQUEST_TYPE_VARIABLES"
          :key="type.code"
          :label="type.label"
          :value="type.code"
        />
      </el-select>
      <!-- IPREQ Status -->
      <el-select
        v-else-if="item.code === 'IP_REQUEST_STATUS'"
        v-model="inputModel"
        @change="onSelect"
        clearable
        :disabled="isReadonly"
        @focus="onfocus"
      >
        <el-option
          v-for="type in IP_REQUEST_STATUS_VARIABLES"
          :key="type.code"
          :label="type.label"
          :value="type.code"
        />
      </el-select>
      <el-input
        v-else
        v-model="inputModel"
        placeholder=""
        :disabled="isReadonly"
        @focus="onfocus"
      />
    </template>
    <!-- 表示用 -->
    <template v-else>
      <div :class="{disabled: isReadonly}" class="preview_item">
        <div v-if="keyName === 'ipApplicationNo'">{{ inputModel }}</div>
        <tw-overflow-tooltip v-else-if="keyName === 'requestDatetime'" :content="requestDatetime | dateTimeFormat" />
        <tw-overflow-tooltip v-else-if="item.inputType === 'date'" :content="inputModel | utcOffsetDateFormat" />
        <tw-overflow-tooltip v-else-if="item.code === 'IP_REQUEST_TYPE'" :content="getSegmentValue(inputModel, IP_REQUEST_TYPE_VARIABLES)" />
        <tw-overflow-tooltip v-else-if="item.code === 'IP_REQUEST_STATUS'" :content="getSegmentValue(inputModel, IP_REQUEST_STATUS_VARIABLES)" />
        <tw-overflow-tooltip v-else :content="inputModel" />
      </div>
    </template>
  </el-form-item>
</template>

<script>
import _ from 'lodash';
import dayjs from 'dayjs';
import { IP_REQUEST_TYPE, IP_REQUEST_TYPE_VARIABLES, IP_REQUEST_STATUS_VARIABLES, IP_SUBMIT_FLG, IP_REQUEST_CANCELED_FLG } from 'lib-tw-common';
import { formatRequestDate, formatResponseDate, onDateChange } from '@/utils/searchUtil.js';
import TwOverflowTooltip from '@/components/atoms/TwOverflowTooltip';

export default {
  name: 'TwProcessGroupListEditIpRequestTableItem',
  props: {
    item: Object,
    tableName: String,
    arrayName: String,
    groupName: String,
    keyName: String,
    value: Object,
    selectIpreqIndex: Number,
    index: Number,
    readonly: Boolean,
    isFrom: Boolean,
    isInsurance: Boolean
  },
  inject: ['s'],
  components: {
    TwOverflowTooltip
  },
  data() {
    return {
      isAmend: false, // Action TypeがAmendかどうか
      IP_REQUEST_TYPE,
      IP_REQUEST_TYPE_VARIABLES,
      IP_REQUEST_STATUS_VARIABLES
    };
  },
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },
    model() {
      return _.get(this, `localValue[${this.tableName}][${this.arrayName}][${this.index}][${this.groupName}][${this.keyName}]`, null);
    },
    inputModel: {
      get() {
        return this.model;
      },
      set(value) {
        this.setValue(value);
      }
    },
    requestDatetime() {
      if (_.isString(this.model)) {
        return dayjs(this.model)
          .utc()
          .local()
          .format('YYYY-MM-DD HH:mm');
      } else {
        return this.model;
      }
    },
    formatResponseDate: {
      get() {
        return formatResponseDate(_.get(this, `localValue[${this.tableName}][${this.arrayName}][${this.index}][${this.groupName}][${this.keyName}]`, null));
      },
      set(value) {
        value = formatRequestDate(value);
        this.setValue(value);
      }
    },
    // submit, action変更可能フラグ
    ipSubmitAbleFlg() {
      return _.get(this, `localValue[${this.tableName}][${this.arrayName}][${this.index}][ipreqInformationGrp].ipSubmitAbleFlg`);
    },
    // submitフラグ
    ipSubmitFlg() {
      return _.get(this, `localValue[${this.tableName}][${this.arrayName}][${this.index}][ipreqInformationGrp].ipSubmitFlg`);
    },
    // actionType
    actionType() {
      return _.get(this, `localValue[${this.tableName}][${this.arrayName}][${this.index}][ipreqInformationGrp].actionType`);
    },
    isReadonly() {
      if (this.readonly) {
        return true;
      }
      // ----- Submit, Action -----
      if (this.keyName === 'ipSubmitFlg') {
        // SubmitはFromのみ編集可能
        return !(this.ipSubmitAbleFlg && this.isFrom);
      } else if (this.keyName === 'actionType') {
        // ActionはFromのみ、Submit:ONの場合のみ編集可能
        // actionTypeにNewが設定されている場合は編集不可
        if (this.actionType === IP_REQUEST_TYPE.NEW) {
          return true;
        }
        return !(this.ipSubmitAbleFlg && this.ipSubmitFlg === IP_SUBMIT_FLG.ON && this.isFrom);
      }
      //----- Submit, Action以外の項目 -----
      // From,To共通でSubmit:ONの場合のみ編集可能
      if (this.ipSubmitFlg === IP_SUBMIT_FLG.OFF) {
        return true;
      }
      if (this.isFrom) {
        // From側の場合
        // Action:Cancelの場合、Fromは編集不可
        return this.actionType === IP_REQUEST_TYPE.CANCEL || !this.item['from'];
      } else {
        // To側の場合 Action:Cancelの場合でも編集可能
        if (this.keyName === 'ipreqStatus') {
          // IPREQ Statusはロールに関係なく編集可能
          return !this.item['to'];
        } else {
          // その他の項目は保険会社ロールのみ編集可能
          return !(this.isInsurance && this.item['to']);
        }
      }
    },
    // Action Typeの選択肢
    actionTypeVariables() {
      if (this.isReadonly) {
        // ReadOnlyの場合
        return IP_REQUEST_TYPE_VARIABLES;
      } else if (this.model === IP_REQUEST_TYPE.NEW) {
        // Action TypeがNewの場合は、Newのみ
        return _.filter(_.cloneDeep(IP_REQUEST_TYPE_VARIABLES), type => type.code === IP_REQUEST_TYPE.NEW);
      } else {
        // Action Typeが選択可能なとき
        return _.filter(_.cloneDeep(IP_REQUEST_TYPE_VARIABLES), type => {
          const requestCancelAbleFlg = _.get(this, `localValue[${this.tableName}][${this.arrayName}][${this.index}][ipreqInformationGrp].requestCancelAbleFlg`);
          if (requestCancelAbleFlg === IP_REQUEST_CANCELED_FLG.ON) {
            // キャンセル可能な場合は、Amend, Cancel（New以外）
            return type.code !== IP_REQUEST_TYPE.NEW;
          } else {
            // キャンセル不可の場合は、Amendのみ
            return type.code === IP_REQUEST_TYPE.AMEND;
          }
        });
      }
    },
    rules() {
      let ignore = false;
      const rules = [
        {
          required: this.s.draftValid || ignore ? false : this.item.validationId.includes('tw-isNotEmpty'),
          lakeelMessageSourceFlg: true,
          validationId: this.s.draftValid || ignore ? _.reject(this.item.validationId, s => s === 'tw-isNotEmpty') : this.item.validationId,
          validator: this.s.onEventValidation,
          trigger: ['select', 'radio', 'checkbox', 'unit', 'currencyId'].includes(this.item.inputType) || ['SYS_MST_PORT_AND_PLACE', 'SYS_MST_COUNTRY'].includes(this.item.code) ? 'change' : 'blur'
        }
      ];

      if (this.item.tsValidationId) {
        const tsValidationIds = _.map(this.item.tsValidationId, tsValidationId => {
          return {
            required: this.s.draftValid ? false : tsValidationId.startsWith('ts-isNotEmpty'),
            validator: this.s.tsValidation,
            tsValidationId: this.s.draftValid && (tsValidationId.startsWith('ts-isNotEmpty') && tsValidationId !== 'ts-isNotEmptyInvoiceAmountOrIpreqCargoAmount') ? undefined : tsValidationId,
            trigger: ['select', 'radio', 'checkbox', 'unit', 'currencyId'].includes(this.item.inputType) || ['SYS_MST_CONTAINER_TYPE', 'SYS_MST_PORT_AND_PLACE', 'SYS_MST_COUNTRY'].includes(this.item.code) ? 'change' : 'blur'
          };
        });
        return [...tsValidationIds, ...rules];
      }
      return rules;
    }
  },
  created() {
    if (this.keyName === 'actionType') {
      // 初期表示時にAction TypeがAmendの場合はフラグを立てる
      this.isAmend = this.model === IP_REQUEST_TYPE.AMEND;
    }
  },
  methods: {
    onfocus(e) {
      this.$emit('onfocus', e, this.keyName);
    },
    // スコープのモデルに値をセットします
    setValue(value) {
      this.s.setArrayValue(this.tableName, this.arrayName, this.index, this.groupName, this.keyName, value);
    },
    // スコープ外のモデルに値をセットします
    setTargetValue(target, value) {
      this.s.setArrayValue(this.tableName, this.arrayName, this.index, this.groupName, target, value);
    },
    onIpSubmitFlgCheck(value) {
      if (value === IP_SUBMIT_FLG.ON) {
        // Submit: OFF → ONに変更時 Registration Noが空の場合は、Action TypeをNEWに設定する
        const registrationNo = _.get(this, `localValue[${this.tableName}][${this.arrayName}][${this.index}][ipreqInformationGrp].registrationNo`);
        if (!registrationNo) {
          this.setTargetValue('actionType', IP_REQUEST_TYPE.NEW);
        }
        this.setValue(value);
      } else {
        if (this.actionType === IP_REQUEST_TYPE.AMEND) {
          // Submit: ON → OFFに変更時 Action: Amendだった場合コンファーム
          this.$store
            .dispatch('SHOW_CONFIRM', 'Changes made during amend will be deleted.')
            .then(() => {
              this.setTargetValue('actionType', null);
              this.setValue(value);
            })
            .catch(() => {
              this.setTargetValue('actionType', IP_REQUEST_TYPE.AMEND);
              this.setTargetValue('ipSubmitFlg', IP_SUBMIT_FLG.ON);
            });
        } else {
          // Submit: ON → OFFに変更時 Action Typeを空に設定する
          this.setTargetValue('actionType', null);
          this.setValue(value);
        }
      }
    },
    onActionSelect(value) {
      if (this.isAmend && value === IP_REQUEST_TYPE.CANCEL) {
        // Action: Amend → Cancel変更時アラート
        this.$store
          .dispatch('SHOW_CONFIRM', 'Changes made during amend will be deleted.')
          .then(() => {
            this.setValue(value);
            this.isAmend = false;
          })
          .catch(() => {
            this.setValue(IP_REQUEST_TYPE.AMEND);
          });
      } else if (value === IP_REQUEST_TYPE.AMEND) {
        // Action: Amendに変更時フラグを立てる
        this.setValue(value);
        this.isAmend = true;
      } else {
        this.setValue(value);
        this.isAmend = false;
      }
    },
    onSelect(value) {
      this.setValue(value);
    },
    onDateChange,
    getSegmentValue(value, variables) {
      return _.get(_.find(variables, { code: value }), 'label', '');
    }
  }
};
</script>

<style lang="scss" scoped>
.el-form-item {
  min-height: 40px;
  &.disabled-check {
    background-color: rgba($color_gray_300, 0.75);
  }
}

::v-deep .el-form-item__content {
  font-size: 14px;
  line-height: 20px;
  color: #3e3a39;
  vertical-align: top;
  flex: 1;

  .el-form-item__error {
    position: relative;
    top: 0;
    max-width: 320px;
  }

  .goods_line_checkbox {
    &.submit {
      min-height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

::v-deep textarea {
  resize: none;
}

.el-select {
  width: 320px;
}

.el-select.unit {
  width: 90px;
  margin-left: 12px;
}

.el-select.packing {
  margin-left: 12px;
}

.el-input-number,
.el-input-number.amount {
  width: 320px;
}

::v-deep .el-date-editor {
  .el-input__inner {
    padding-left: 16px !important;
    text-align: center;
  }
}

.preview_item {
  font-size: 12px;
  color: $color_black;
  padding: 0 20px 0 5px;
  width: 100%;
  min-height: 40px;
  overflow: hidden;
  display: flex;
  align-items: center;
  // background: white;
  &.disabled {
    background-color: rgba($color_gray_300, 0.75);
  }
}
</style>
